/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.upper {
	text-transform: uppercase;
}

.text-td-start {
	font-size: small;
	font-weight: 900;
	text-align: left;
}

.text-observations {
	font-size: small;
	font-weight: 500;
}

.w-td-start {
	width: 25rem;
}

.spinner-loading-page {
	width: 4rem;
	height: 4rem;
	text-align: center;
}

.disabled-upload-container {
	opacity: 0.5;
	cursor: default !important;
}

.cursor-default {
	cursor: default !important;
}

.content-hidden {
	display: none;
}

.cursor-pointer {
	cursor: pointer !important;
}

.text-center {
	text-align: center !important;
}

.loader-progress-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.loading-progress-icon {
	text-align: center;		
	margin: 0 auto;
}

.loading-progress-text {
	margin-bottom: 1rem;
	font-size: 1.2rem;
}

.window-progress {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	z-index: 9999;
}

.logo-header-archivenomy {
	width: 11rem;
}

.header-image-modal {
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 250px;
}

.layer-opacity {
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.title-header-image {
	position: relative;
	margin: auto;
	color: #ffffff;
	text-align: center;
	top: 25%;
	font-weight: bolder;
	letter-spacing: 2px;
}

.input-w-100 {
	width: 100%;
}

.swiper {
	text-align: center !important;
}

.card-img-h {
	height: 320px;
	background-size: cover !important;
}
.bt-x-modal {
	z-index: 9;
}
.mt-modal {
	margin-top: 155px;
}
.lower {
	text-transform: lowercase;
}
.row-center {
	justify-content: center;
	align-items: center;
}

.link-a {
	color: blue !important;
	&:hover {
		text-decoration: underline !important;
		color: blue !important;
	}
}

.bc-header {
	background-color: beige;
}